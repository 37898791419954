<template>
    <v-card-text>
        <p>Email: {{ email }}</p>
        <p>Pseudonym: {{ pseudonym }}</p>
        <p class="subtitle">Display name: {{ displayName }}</p>
        <p class="subtitle">Id: {{ id }}</p>
        <p class="subtitle">Created on: {{ createdOn }}</p>
    </v-card-text>
</template>

<script>
export default {
    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        email() {
            return this.attr.email;
        },
        pseudonym() {
            return this.attr.alias; // TODO: rename to pseudonym
        },
        createdOn() {
            return new Date(this.attr.created_on).toString();
        },
        firstName() {
            if (this.attr.info.firstName) {
                return this.attr.info.firstName;
            }
            return '';
        },
        lastName() {
            if (this.attr.info.lastName) {
                return this.attr.info.lastName;
            }
            return '';
        },
        displayName() {
            if (this.attr.display_name) {
                return this.attr.display_name;
            }
            return '';
        },
        /*
        verifiedEmail() {
            if (this.attr.content.verified && this.attr.content.verified.email) {
                return this.attr.content.verified.email;
            }
            return [];
        },
        */
        json() {
            return JSON.stringify(this.attr, null, 2);
        },
    },
};
</script>
